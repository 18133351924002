<template>
    <v-card flat class="">
        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Kelompok</th>
                        <th>Praktik/Stase</th>
                        <th>Tanggal Awal</th>
                        <th>Tanggal Akhir</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, key) in table.items" :key="item.id">
                        <td>{{ key + 1 }}</td>
                        <td>{{ item.profesi }}</td>
                        <td>{{ item.nama }}, {{ item.stase }}</td>
                        <td>{{ formatDate(item.tanggal_awal) }}</td>
                        <td>{{ formatDate(item.tanggal_akhir) }}</td>
                        <td>
                            <span v-if="item.flag_end == 0">Aktif</span>
                            <span v-else>Non Aktif</span>
                        </td>
                        <td>
                            <span v-if="item.flag_end == 0">
                                <span v-if="user.role == 'PESERTA DIDIK'">
                                    <span v-if="user.profesi == 'Dokter'">
                                        <router-link :to="{ name: 'UserDaftarHadir', params: { praktik_id: item.id } }">
                                            <v-list-item-title>Daftar Hadir</v-list-item-title>
                                        </router-link>
                                        <router-link :to="{ name: 'UserLogbook', params: { praktik_id: item.id } }">
                                            <v-list-item-title>Logbook</v-list-item-title>
                                        </router-link>
                                        <router-link
                                            :to="{ name: 'UserTingkatSupervisi', params: { praktik_id: item.id } }">
                                            <v-list-item-title>Tingkat Supervisi</v-list-item-title>
                                        </router-link>
                                        <router-link :to="{ name: 'UserLaporanJaga', params: { praktik_id: item.id } }">
                                            <v-list-item-title>Laporan Jaga</v-list-item-title>
                                        </router-link>
                                        <router-link :to="{ name: 'UserBst', params: { praktik_id: item.id } }">
                                            <v-list-item-title>BST</v-list-item-title>
                                        </router-link>
                                        <router-link :to="{ name: 'UserUjian', params: { praktik_id: item.id } }">
                                            <v-list-item-title>Ujian</v-list-item-title>
                                        </router-link>
                                        <router-link :to="{ name: 'UserJurnalReading', params: { praktik_id: item.id } }">
                                            <v-list-item-title>Jurnal Reading</v-list-item-title>
                                        </router-link>
                                    </span>
                                    <span v-if="user.profesi == 'PPDS'">
                                        <router-link :to="{ name: 'UserDaftarHadir', params: { praktik_id: item.id } }">
                                            <v-list-item-title>Daftar Hadir</v-list-item-title>
                                        </router-link>
                                        <router-link :to="{ name: 'UserLogbook', params: { praktik_id: item.id } }">
                                            <v-list-item-title>Logbook</v-list-item-title>
                                        </router-link>
                                    </span>
                                    <span v-if="user.profesi == 'Keperawatan,Kebidanan, dan Tenaga Kesehatan Lain'">
                                        <router-link :to="{ name: 'UserDaftarHadir', params: { praktik_id: item.id } }">
                                            <v-list-item-title>Daftar Hadir</v-list-item-title>
                                        </router-link>
                                        <router-link :to="{ name: 'UserLogbook', params: { praktik_id: item.id } }">
                                            <v-list-item-title>Logbook</v-list-item-title>
                                        </router-link>
                                        <router-link
                                            :to="{ name: 'UserTingkatSupervisi', params: { praktik_id: item.id } }">
                                            <v-list-item-title>Tingkat Supervisi</v-list-item-title>
                                        </router-link>
                                        <router-link :to="{ name: 'UserUjian', params: { praktik_id: item.id } }">
                                            <v-list-item-title>Ujian</v-list-item-title>
                                        </router-link>
                                    </span>

                                </span>
                                <span v-if="user.role == 'PEMBIMBING KLINIK'">
                                    <router-link :to="{ name: 'PembimbingLogbook', params: { praktik_id: item.id } }">
                                        <v-list-item-title>Logbook</v-list-item-title>
                                    </router-link>
                                    <router-link
                                        :to="{ name: 'PembimbingVerifikasiDaftar', params: { praktik_id: item.id } }">
                                        <v-list-item-title>Verifikasi Daftar Hadir</v-list-item-title>
                                    </router-link>
                                    <router-link
                                        :to="{ name: 'PembimbingVerifikasiLogbook', params: { praktik_id: item.id } }">
                                        <v-list-item-title>Verifikasi Logbook</v-list-item-title>
                                    </router-link>
                                    <router-link
                                        :to="{ name: 'PembimbingVerifikasiTingkat', params: { praktik_id: item.id } }">
                                        <v-list-item-title>Verifikasi Tingkat Supervisi</v-list-item-title>
                                    </router-link>
                                    <router-link v-if="item.profesi == 'Dokter'"
                                        :to="{ name: 'PembimbingEvaluasiDokter', params: { praktik_id: item.id } }">
                                        <v-list-item-title>Evaluasi Peserta Didik</v-list-item-title>
                                    </router-link>
                                    <router-link v-else-if="item.profesi == 'PPDS'"
                                        :to="{ name: 'PembimbingEvaluasiPPDS', params: { praktik_id: item.id } }">
                                        <v-list-item-title>Evaluasi Peserta Didik</v-list-item-title>
                                    </router-link>
                                    <router-link v-else-if="item.profesi == 'Keperawatan,Kebidanan, dan Tenaga Kesehatan Lain'"
                                        :to="{ name: 'PembimbingEvaluasiLain', params: { praktik_id: item.id } }">
                                        <v-list-item-title>Evaluasi Peserta Didik</v-list-item-title>
                                    </router-link>
                                </span>
                            </span>

                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script>
import PilihMultiUser from '@/views/PilihMultiUser.vue'
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiDelete, mdiPencil, mdiEye } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIROOT
export default {
    components: {
        PilihMultiUser,
    },
    data() {
        return {
            data: {
                nama_institusi: '',
				nama_prodi: '',
                profesi: '',
                stase: '',
                tanggal_awal: '',
                tanggal_akhir: '',
            },
            icons: {
                mdiDelete,
                mdiPencil, mdiEye
            },
            tableOptions: {},
            table: {
                loading: true,
                items: [],
                totalItems: 0,
            },
            dialog: false,
            form: {
                editedIndex: -1,
                editedItem: {
                    role: '',
                    selectedUsers: [],
                },
                defaultItem: {
                    role: '',
                    selectedUsers: [],
                },
                roles: [
                    'PESERTA DIDIK', 'PEMBIMBING KLINIK'
                ],
                users: [],
            },
        }
    },
    watch: {
    },
    computed: {
        formTitle() {
            return this.form.editedIndex === -1 ? 'Tambah Item' : 'Edit Item'
        },
        user() {
            return this.$store.getters.user
        },
    },
    created() {
        this.getPraktikUser()
    },
    methods: {
        save() {
            if (this.form.editedIndex > -1) {
            } else {
                const fmData = new FormData()
                fmData.append('praktik_id', this.praktik_id)
                fmData.append('role', this.form.editedItem.role)
                fmData.append('users', this.form.editedItem.selectedUsers)
                axiosPostAuth('api/Praktik/addUserPraktik', fmData)
                    .then(response => {
                        if (response.code === 200) {
                            this.getDataFromApi()
                            this.dialog = false
                        }
                        else {
                            alert(response.message)
                        }
                    })
                    .catch(error => {
                        alert(error)
                    })
            }
        },
        getPraktikUser() {
            const fmData = new FormData()
            axiosPostAuth('api/Praktik/getPraktikUser', fmData)
                .then(response => {
                    this.table.items = response.data
                })
                .catch(error => {
                    alert(error)
                })
        },
        getDetail() {
            const fmData = new FormData()
            fmData.append('id', this.praktik_id)
            axiosPostAuth('api/Praktik/getPraktik', fmData)
                .then(response => {
                    this.data = Object.assign({}, response.data)
                })
                .catch(error => {
                    alert(error)
                })
        },
        deleteItem(item) {
            const conf = confirm("Apakah yakin akan dihapus?")
            if (conf) {
                const fmData = new FormData()
                fmData.append('id', item.id)
                axiosPostAuth('api/Praktik/deleteUserPraktik', fmData)
                    .then(response => {
                        if (response.code === 200) {
                            this.getDataFromApi()
                        }
                        else {
                            alert(response.message)
                        }
                    })
                    .catch(error => {
                        alert(error)
                    })
            }
        },
    },
}
</script>